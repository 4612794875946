import React from "react"
import {graphql, Link, StaticQuery, useStaticQuery} from "gatsby"

import Layout from "../components/layout"
import Img from "gatsby-image"
import Meta from "../components/meta"
import teamBgImg from "../images/about_bg_1.svg";
import linkedinIconImg from "../images/linkdin.png";
import ourStoryImg from "../images/blue_layer.png";
import samuraiImg from "../images/ip1.png";
import nielsenImg from "../images/ip2.png";
import tiproyecImg from "../images/ip3.png";
import gruporepulloImg from "../images/ip4.png";
import bseedImg from "../images/ip5.png";
import RequestDemoMiniForm from "../components/request-demo-mini-form";

const KnowledgeCenter = ({data, location}) => {
    if (!data) {
        return false;
    }
    // const posts = data.allMdx.edges;
    const posts = data.allMediumFeed.edges;
    return (
        <Layout location={location}>
            <Meta title="Knowledge center"/>
            <div className="banner about_banner careers_banner knowledge_center_banner">
                <div className={'wrapper'}>
                    <div className="banner_content">
                        <h1>Knowledge center</h1>
                    </div>
                </div>
            </div>
            <div className="knowledge">
                <div className="wrapper">
                    {posts.map(({node}) => {
                        return (
                            <Link to={'/knowledge-center/' + node.slug + '/'} className="col">
                                <div className="img_col">
                                    {node.featuredImg && (
                                        <Img fluid={node.featuredImg.childImageSharp.fluid}/>
                                    )}
                                </div>
                                <div className="text_col">
                                    <span>{node.date}</span>
                                    <h3>{node.title}</h3>
                                    <span className="read_more">Read More</span>
                                </div>
                            </Link>
                        );
                    })}
                </div>
            </div>
            <div className="salse_col">
                <div className="wrapper">
                    <div className="in_sales cf">
                        <div className="text_right">
                            <h3>Want to increase your sales?</h3>
                        </div>
                        <div className="con_mail">
                            <RequestDemoMiniForm/>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default KnowledgeCenter;

export const fluidImage = graphql`
    fragment fluidImage on File {
        childImageSharp {
            fluid(quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
                ...GatsbyImageSharpFluidLimitPresentationSize
            }
        }
    }
`;

export const pageQuery = graphql`
    query {
        allMediumFeed(sort: { fields: [date], order: DESC }) {
            edges {
                node {
                    title
                    date(formatString: "MMMM DD, YYYY")
                    featuredImg { ...fluidImage }
                    slug
                }
            }
        }
    }
`;
/*export const pageQuery = graphql`
    query {
        allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
            edges {
                node {
                    excerpt
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        title
                        image { ...fluidImage }
                        slug
                    }
                }
            }
        }
    }
`*/
;